@media (max-width: 992px) {
	
	.header {
		padding-top: 10px;
		.container {
			flex-wrap: wrap;
			padding-right: 60px;
		}
	}

	.menu {
		order: 2;
		width: 100%;
		justify-content: center;
	}

	.logotypes-row {
		&__item {
			transform: scale(.7);
			transform-origin: center;
			max-width: 45%;
			margin-bottom: 0px;
		}
	}

	.section-logotypes {
		padding-top: 30px;
	}

	.pay-cols {
		flex-wrap: wrap;
	}

	.pay-info {
		margin-right: 0px;
		margin-bottom: 50px;

	}

	.pay-types {
		width: 100%;
		.list-check {
			text-align: left;
			&>li {
				margin-bottom: 10px;
			}
		}
		&__title {
			text-align: left;
		}
	}

	.inner-page {
		padding-top: 155px;
	}





	
}