/****************************************************
Base config
****************************************************/
* {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
}

body {
  font: 14px 'open_sans', Arial, sans-serif;
  width: 100%;
  height: 100%;
  line-height: 20px;
  background: #fff;
}

a {
  color: #00b2fc;
  outline: none;
  text-decoration: underline;
  transition: .5s;
}

a:hover {
  text-decoration: none;
}

img {
  border: 0;
  max-width: 100%;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  -webkit-appearance: none;
  border-radius: 0;
}

button {
  cursor: pointer;
  border: 0;
  outline: none;
}

input {
  vertical-align: middle;
  outline: none;
  border: 0;
}

input[type="submit"], input[type="button"] {
  cursor: pointer;
  -webkit-appearance: none;
}

textarea {
  outline: none;
  resize: none;
  overflow: auto;
}

:invalid {
  box-shadow: none;
}

ol, ul {
  list-style-position: inside;
}

::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

::-moz-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-moz-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-ms-input-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wrapper {
  min-height: 100%;
  height: auto;
  min-width: 320px;
  overflow: hidden;
}

.container {
  max-width: 1170px;
  padding-left: 30px;
  padding-right: 30px;
  margin: 0 auto;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.hidden {
  overflow: hidden;
}

.clearfix:before {
  content: "";
  display: table;
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.win-opened {
  overflow: hidden;
}

/*for sticky footer*/
.content {
  padding-bottom: 345px;
}

.footer {
  margin-top: -345px;
}

/****************************************************
Connect fonts
****************************************************/
@font-face {
  font-family: 'open_sans';
  src: url("../fonts/opensans-bold-webfont.eot");
  src: url("../fonts/opensans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-bold-webfont.woff2") format("woff2"), url("../fonts/opensans-bold-webfont.woff") format("woff"), url("../fonts/opensans-bold-webfont.ttf") format("truetype"), url("../fonts/opensans-bold-webfont.svg#open_sansbold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url("../fonts/opensans-extrabold-webfont.eot");
  src: url("../fonts/opensans-extrabold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-extrabold-webfont.woff2") format("woff2"), url("../fonts/opensans-extrabold-webfont.woff") format("woff"), url("../fonts/opensans-extrabold-webfont.ttf") format("truetype"), url("../fonts/opensans-extrabold-webfont.svg#open_sansextrabold") format("svg");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url("../fonts/opensans-light-webfont.eot");
  src: url("../fonts/opensans-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-light-webfont.woff2") format("woff2"), url("../fonts/opensans-light-webfont.woff") format("woff"), url("../fonts/opensans-light-webfont.ttf") format("truetype"), url("../fonts/opensans-light-webfont.svg#open_sanslight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url("../fonts/opensans-regular-webfont.eot");
  src: url("../fonts/opensans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-regular-webfont.woff2") format("woff2"), url("../fonts/opensans-regular-webfont.woff") format("woff"), url("../fonts/opensans-regular-webfont.ttf") format("truetype"), url("../fonts/opensans-regular-webfont.svg#open_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'open_sans';
  src: url("../fonts/opensans-semibold-webfont.eot");
  src: url("../fonts/opensans-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/opensans-semibold-webfont.woff2") format("woff2"), url("../fonts/opensans-semibold-webfont.woff") format("woff"), url("../fonts/opensans-semibold-webfont.ttf") format("truetype"), url("../fonts/opensans-semibold-webfont.svg#open_sanssemibold") format("svg");
  font-weight: 600;
  font-style: normal;
}

/****************************************************
Buttons
****************************************************/
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: .5s;
  text-decoration: none;
  cursor: pointer;
  text-align: center;
  background-color: transparent;
}

.btn_theme1 {
  font-size: 18px;
  font-weight: 700;
  border: 3px solid #fff;
  border-radius: 10px;
  color: #fff;
  height: 52px;
  min-width: 280px;
  padding-left: 15px;
  padding-right: 15px;
}

.btn_theme1:hover {
  background: #fff;
  color: #016a76;
}

.btn_theme1_medium {
  height: 40px;
  font-weight: 600;
}

.btn_theme2 {
  font-size: 16px;
  border: 3px solid #7f7f7f;
  border-radius: 9px;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  box-shadow: 4px 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.btn_theme2:hover {
  background: #7f7f7f;
  color: #fff;
}

.btn_theme2_medium {
  height: 40px;
  font-weight: 600;
}

.btn_soc__text {
  margin: 0 10px;
  color: #fff;
}

.btn_menu {
  display: none;
  position: absolute;
  z-index: 160;
  top: 25px;
  right: 15px;
  width: 25px;
  height: 20px;
  z-index: 220;
  cursor: pointer;
}

.btn_menu span, .btn_menu:before, .btn_menu:after {
  content: "";
  display: block;
  width: 30px;
  height: 3px;
  background-color: #fff;
  position: absolute;
  left: 0;
  transition: all 300ms ease-in-out;
  border-radius: 20px;
}

.btn_menu:before {
  top: 0px;
}

.btn_menu span {
  top: 8px;
  opacity: 1;
}

.btn_menu:after {
  top: 16px;
}

.btn_menu.active span {
  opacity: 0;
  top: 5px;
  width: 30px;
  transform: rotate(45deg);
  background: #000;
}

.btn_menu.active:before {
  width: 30px;
  top: 5px;
  left: 0;
  transform: rotate(45deg);
  background: #000;
}

.btn_menu.active:after {
  width: 30px;
  top: 5px;
  left: 0;
  transform: rotate(-45deg);
  background: #000;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  outline: none;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #000;
  opacity: .5;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 20px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 960px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.75;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

/****************************************************
Header
****************************************************/
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(16, 22, 54, 0.2);
  z-index: 100;
}

.header__logo {
  width: 220px;
}

.header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_inner {
  background: #05121c;
  border-bottom: 2px solid #cfd0d7;
}

.header.fixed {
  position: fixed;
  background: rgba(16, 22, 54, 0.8);
}

.inner-page {
  padding-top: 77px;
}

.contact-item__link {
  padding-left: 22px;
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}

.contact-item__link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.contact-item__link_phone:before {
  width: 14px;
  height: 16px;
  background: url(../img/icon-phone.svg);
  background-size: cover;
}

.contact-item__link_mail:before {
  width: 16px;
  height: 11px;
  background: url(../img/icon-mail.svg);
  background-size: cover;
}

.contact-item__link_skype:before {
  width: 16px;
  height: 16px;
  background: url(../img/icon-skype.png);
  background-size: cover;
}

.section-main__title {
  font-size: 62px;
  line-height: 66px;
  font-weight: 300;
  margin-bottom: 7px;
}

.section-main__subtitle {
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
  margin-bottom: 35px;
}

.menu {
  list-style: none;
  display: flex;
}

.menu > li {
  padding: 25px 15px 0;
  box-sizing: border-box;
  position: relative;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
  height: 75px;
}

.menu > li:last-child {
  border-right: 0;
}

.menu > li > a {
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  display: inline-block;
}

.menu > li.current {
  position: relative;
}

.menu > li:hover .menu__lvl2 {
  opacity: 1;
  visibility: visible;
}

.menu__lvl2 {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  background: rgba(3, 7, 15, 0.55);
  list-style: none;
  box-sizing: border-box;
  opacity: 0;
  visibility: hidden;
  transition: .3s;
}

.menu__lvl2 > li {
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.menu__lvl2 > li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
}

.menu__lvl2 > li > a {
  color: #fff;
  font-size: 13px;
  text-decoration: none;
  display: block;
  padding: 7px 5px;
}

.menu__lvl2 > li > a:hover {
  background: rgba(3, 7, 15, 0.5);
}

.menu-item-arrow > a {
  position: relative;
  padding-right: 20px;
}

.menu-item-arrow > a:after {
  content: "";
  position: absolute;
  right: 0;
  top: 6px;
  width: 12px;
  height: 9px;
  background: url(../img/icon-arrow-down.svg) no-repeat;
}

.btn-wrapper {
  text-align: center;
}

.btn-wrapper__inner {
  position: relative;
  display: inline-flex;
}

.btn-wrapper__inner:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #32b0ee;
}

.btn-wrapper__inner:after {
  content: "";
  position: absolute;
  right: -45px;
  top: 50%;
  width: 30px;
  height: 1px;
  background: #32b0ee;
}

.main-slider {
  position: relative;
}

.main-slider .slick-dots {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  list-style: none;
  font-size: 0;
}

.main-slider .slick-dots li {
  margin-bottom: 3px;
}

.main-slider .slick-dots li button {
  width: 8px;
  height: 8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  background: transparent;
}

.main-slider .slick-dots li.slick-active button {
  background: #fff;
}

.main-slider__item {
  background-repeat: no-repeat;
  background-position: center 0;
  background-size: cover;
  height: 799px;
  box-sizing: border-box;
  padding-top: 356px;
  text-align: center;
  color: #fff;
}

.title {
  text-transform: uppercase;
  text-align: center;
  font-size: 28px;
  line-height: 32px;
  color: #016a76;
  font-weight: 800;
  margin-bottom: 15px;
}

.advantage {
  padding: 0 15px;
}

.advantage__icon {
  border: 1px solid #066d79;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  line-height: 0;
  margin-right: 20px;
}

.advantage__title {
  color: #062033;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.advantage__head {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.advantage__text {
  color: #062033;
  font-size: 13px;
  line-height: 20px;
  padding-left: 60px;
}

.slider-advantages {
  margin: 0 -15px;
}

.slider-advantages .slick-dots {
  list-style: none;
  font-size: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 18px;
}

.slider-advantages .slick-dots li {
  margin: 0 7px;
}

.slider-advantages .slick-dots li button {
  width: 8px;
  height: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background: transparent;
}

.slider-advantages .slick-dots li.slick-active button {
  background: #000;
}

.section-advantages {
  background: #f4f7f9;
  padding: 70px 0 40px;
}

.subtitle-figure {
  text-align: center;
  margin-bottom: 45px;
}

.subtitle-figure__icon {
  position: relative;
  display: inline-block;
  line-height: 0;
}

.subtitle-figure__icon:before {
  content: "";
  position: absolute;
  left: -100px;
  top: 50%;
  width: 85px;
  height: 1px;
  background: rgba(6, 32, 51, 0.1);
}

.subtitle-figure__icon:after {
  content: "";
  position: absolute;
  right: -100px;
  top: 50%;
  width: 85px;
  height: 1px;
  background: rgba(6, 32, 51, 0.1);
}

.block-desc {
  text-align: center;
  color: #062033;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  max-width: 930px;
  margin: 0 auto 55px;
}

.section-tariffs {
  padding: 90px 0 30px;
  background: #f4f7f9;
}

.section-tariffs .subtitle-figure {
  margin-bottom: 20px;
}

.section-tariffs_spec {
  background: #fff;
}

.list-desc {
  max-width: 930px;
  margin: 0 auto 55px;
  color: #062033;
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  list-style: none;
  opacity: .8;
}

.list-desc > li {
  margin-bottom: 5px;
}

.list-desc > li:last-child {
  margin-bottom: 0;
}

.tariff-block {
  width: 275px;
  box-shadow: 4px 3px 5px 0 rgba(0, 0, 0, 0.5);
  background: linear-gradient(to right, #000, #2898c7);
  color: #fff;
  border-radius: 10px;
  padding: 25px 20px;
  margin: 0 25px 30px;
  box-sizing: border-box;
}

.tariff-block__top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tariff-block__top__icon {
  margin-right: 10px;
  width: 30px;
  flex-shrink: 0;
  line-height: 0;
}

.tariff-block__top__title {
  font-size: 24px;
  line-height: 24px;
}

.tariff-block__btn {
  text-align: center;
  position: relative;
  padding-top: 20px;
}

.tariff-block__btn .btn {
  min-width: 195px;
  box-shadow: 4px 3px 5px 0 rgba(0, 0, 0, 0.2);
}

.tariff-block__price {
  background: #fff;
  border-radius: 0 50px 50px 0;
  height: 103px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #00b5a7;
  font-size: 18px;
  margin: 0 0 28px -20px;
  padding-left: 20px;
  width: 230px;
  box-sizing: border-box;
}

.tariff-block__price__main {
  font-size: 41px;
  line-height: 41px;
  margin-bottom: 10px;
}

.tariff-block__price-period {
  text-align: center;
  max-width: 170px;
}

.tariff-block .list-check {
  min-height: 96px;
  margin-bottom: 3px;
}

.list-check {
  list-style: none;
}

.list-check > li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 8px;
}

.list-check > li:last-child {
  margin-bottom: 0;
}

.list-check > li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 23px;
  height: 16px;
  background: url(../img/icon-check.svg) no-repeat;
}

.list-check_right-align {
  text-align: right;
}

.list-check_right-align > li {
  padding-left: 0;
  padding-right: 30px;
}

.list-check_right-align > li:before {
  right: 0;
  left: auto;
}

.list-check_margin-set > li {
  margin-bottom: 25px;
}

.list-check_margin-set > li:last-child {
  margin-bottom: 0;
}

.tariffs-title {
  text-align: center;
  color: #016a76;
  margin-bottom: 35px;
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
}

.tariffs-row {
  display: flex;
  margin: 0 -25px;
  justify-content: center;
  flex-wrap: wrap;
}

.tariffs-wrapper {
  margin-bottom: 55px;
}

.tariffs-wrapper:last-child {
  margin-bottom: 0;
}

.section-partners {
  background: #f4f7f9;
}

.section-pay {
  background: url(../img/bg-city.jpg) no-repeat center 0;
  background-size: cover;
  color: #fff;
  padding: 58px 0 32px;
}

.section-pay .title {
  color: #fff;
}

.section-pay .subtitle-figure__icon:after, .section-pay .subtitle-figure__icon:before {
  background: #8b949c;
}

.pay-cols {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.pay-logos {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(244, 247, 249, 0.2);
  padding: 22px;
  margin-top: 50px;
}

.pay-logos__item {
  margin: 0 17px;
  line-height: 0;
}

.pay-types {
  color: rgba(232, 233, 234, 0.8);
  margin: 0 15px;
}

.pay-types__title {
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 27px;
}

.pay-types .list-check {
  font-weight: 600;
}

.pay-types .list-check > li {
  font-size: 16px;
  margin-bottom: 4px;
}

.pay-types_col1 {
  width: 280px;
}

.pay-types_col2 {
  width: 320px;
}

.pay-info {
  background: rgba(244, 247, 249, 0.2);
  padding: 15px 25px 25px;
  color: rgba(232, 233, 234, 0.8);
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  box-sizing: border-box;
  width: 440px;
  border-radius: 12px;
  margin-right: 30px;
}

.contacts-col {
  width: 250px;
  flex-shrink: 0;
  margin-right: 50px;
}

.contacts-col__title {
  margin-bottom: 25px;
  color: #062033;
  font-size: 22px;
  line-height: 22px;
}

.contacts-col__main {
  color: #6a737b;
  font-size: 13px;
  line-height: 24px;
}

.contacts-col__main a {
  color: #6a737b;
  text-decoration: none;
}

.contact-form {
  flex-grow: 1;
  width: 100%;
}

.contact-form__title {
  color: #062033;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 40px;
}

.contact-form__main {
  margin: 0 -15px;
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
}

.contact-form__field {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 35px;
  box-sizing: border-box;
}

.contact-form__field__label {
  font-size: 13px;
  color: #5b646e;
  opacity: .5;
}

.contact-form__field input {
  padding-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid #ccc;
}

.contact-form__btn {
  margin-top: -15px;
  text-align: right;
  max-width: 770px;
}

.contacts-wrapper {
  display: flex;
}

.icon {
  display: inline-block;
}

.icon-mail {
  width: 23px;
  height: 16px;
  background: url(../img/icon-mail2.svg) no-repeat;
  margin-right: 8px;
}

.icon-arrow-right {
  width: 17px;
  height: 12px;
  background: url(../img/icon-arrow-right.svg) no-repeat;
  background-size: cover;
}

.section-contacts {
  padding-top: 32px;
  padding-bottom: 36px;
}

.section-contacts .subtitle-figure {
  margin-bottom: 20px;
}

.footer {
  background: #122536;
  color: #fff;
  line-height: 24px;
  font-size: 13px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer__main {
  display: flex;
  justify-content: space-between;
}

.footer__col1 {
  width: 270px;
  margin-right: 30px;
}

.footer__col2 {
  width: 230px;
  padding-top: 25px;
}

.footer__col2 .footer-title {
  margin-bottom: 20px;
}

.footer__logo {
  width: 220px;
  margin-bottom: 15px;
}

.footer__section {
  padding-top: 25px;
}

.footer-menu-wrap {
  border-right: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  margin-right: 15px;
  margin-top: 10px;
}

.footer-menu {
  list-style: none;
  margin-right: 15px;
}

.footer-menu > li {
  margin-bottom: 8px;
}

.footer-menu > li:last-child {
  margin-bottom: 0;
}

.footer-menu > li > a {
  font-size: 13px;
  color: #fff;
  text-decoration: none;
}

.footer-menu > li > a:hover {
  text-decoration: underline;
}

.footer-bottom {
  margin-top: 30px;
}

.footer-bottom a {
  text-decoration: none;
}

.footer-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  min-height: 48px;
}

.footer-other {
  width: 240px;
}

.footer-cols-wrap {
  display: flex;
}

.footer-right {
  align-items: center;
}

.link-conf {
  margin-bottom: 50px;
}

.link-conf a {
  text-decoration: none;
  color: #fff;
}

.link-conf a:hover {
  text-decoration: underline;
}

.tariff-col-wrap {
  width: 270px;
  margin-left: 30px;
}

.tariff-col-wrap .tariff-block {
  margin: 0;
}

.tariff-col-wrap .tariff-block__price {
  margin-bottom: 50px;
}

.contacts-form-wrapper {
  display: flex;
  align-items: center;
}

.form-note {
  font-size: 13px;
  margin-top: 8px;
}

.form-theme-mod2 .contact-form__field__label {
  color: #000;
  opacity: 1;
}

.section-logotypes {
  padding: 70px 0 0px;
}

.logotypes-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin-left: -10px;
  margin-right: -10px;
}

.logotypes-row__item {
  line-height: 0;
  margin: 0 5px 65px;
}

.modal {
  background: #fff;
  padding: 50px;
  border-radius: 6px;
  text-align: left;
  max-width: 500px;
  box-sizing: border-box;
  margin: 40px auto;
  box-shadow: 0 4px 15px 0 rgba(0, 18, 84, 0.07);
  position: relative;
}

.modal__title {
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  font-weight: 700;
}

/****************************************************
Footer
****************************************************/
@media (max-width: 1229px) {
  .footer-cols-wrap {
    flex-direction: column;
  }
  .footer__main {
    flex-wrap: wrap;
  }
  .footer-menu-wrap {
    border-right: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .footer-right {
    align-items: flex-start;
  }
}

@media (max-width: 992px) {
  .header {
    padding-top: 10px;
  }
  .header .container {
    flex-wrap: wrap;
    padding-right: 60px;
  }
  .menu {
    order: 2;
    width: 100%;
    justify-content: center;
  }
  .logotypes-row__item {
    transform: scale(0.7);
    transform-origin: center;
    max-width: 45%;
    margin-bottom: 0px;
  }
  .section-logotypes {
    padding-top: 30px;
  }
  .pay-cols {
    flex-wrap: wrap;
  }
  .pay-info {
    margin-right: 0px;
    margin-bottom: 50px;
  }
  .pay-types {
    width: 100%;
  }
  .pay-types .list-check {
    text-align: left;
  }
  .pay-types .list-check > li {
    margin-bottom: 10px;
  }
  .pay-types__title {
    text-align: left;
  }
  .inner-page {
    padding-top: 155px;
  }
}

@media (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .menu {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 100;
    padding: 50px 15px;
    box-sizing: border-box;
  }
  .menu > li {
    padding: 0;
    width: 100%;
    text-align: center;
    height: auto;
    margin-bottom: 15px;
  }
  .menu > li > a {
    color: #000;
  }
  .menu > li.current:before {
    display: none;
  }
  .menu > li:hover .menu__lvl2 {
    display: block;
  }
  .menu.active {
    visibility: visible;
    opacity: 1;
  }
  .menu__lvl2 {
    position: static;
    display: none;
  }
  .btn_menu {
    display: block;
  }
  .header__contacts {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
  }
  .header .container {
    padding-right: 15px;
  }
  .main-slider__item {
    height: auto;
    padding-top: 200px;
    padding-bottom: 100px;
  }
  .main-slider .slick-dots {
    right: 15px;
  }
  .section-main__title {
    font-size: 34px;
    line-height: 40px;
  }
  .section-main__subtitle {
    font-size: 26px;
    line-height: 32px;
  }
  .btn_theme1 {
    min-width: 100%;
  }
  .pay-logos {
    flex-wrap: wrap;
    padding: 10px;
  }
  .pay-logos__item {
    margin: 10px;
  }
  .contacts-wrapper {
    flex-wrap: wrap;
  }
  .contacts-col {
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
  .contact-form__field {
    width: 100%;
  }
  .footer__col1 {
    width: 100%;
  }
  .footer__section {
    width: 100%;
  }
  .footer-menu {
    margin-right: 0;
    margin-bottom: 8px;
  }
  .footer-menu-wrap {
    flex-direction: column;
  }
  .tariff-col-wrap {
    margin-left: 0;
    margin-bottom: 50px;
  }
  .contacts-form-wrapper {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
