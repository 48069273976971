/****************************************************
Buttons
****************************************************/
.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	transition: .5s;
	text-decoration: none;
	cursor: pointer;
	text-align: center;
	background-color: transparent;
	&_theme1 {
		font-size: 18px;
		font-weight: 700;
		border: 3px solid #fff;
		border-radius: 10px;
		color: #fff;
		height: 52px;
		min-width: 280px;
		padding-left: 15px;
		padding-right: 15px;
		&:hover {
			background: #fff;
			color: #016a76;	
		}
		&_medium {
			height: 40px;
			font-weight: 600;
		}
	}
	&_theme2 {
		font-size: 16px;
		border: 3px solid #7f7f7f;
		border-radius: 9px;
		height: 40px;
		padding-left: 20px;
		padding-right: 20px;
		box-shadow: 4px 3px 5px 0 rgba(0,0,0,.2);
		&:hover {
			background: #7f7f7f;
			color: #fff;
		}
		&_medium {
			height: 40px;
			font-weight: 600;
		}
	}
	&_soc {
		&__text {
			margin: 0 10px;
			color: #fff;
		}
	}

	&_menu {
		display: none;
		position: absolute;
		z-index: 160;
		top: 25px;
		right: 15px;
		width: 25px;
		height: 20px;
		z-index: 220;
		cursor: pointer;
		& span,
		&:before,
		&:after{
			content: "";
			display: block;
			width: 30px;
			height: 3px;
			background-color: #fff;
			position: absolute;
			left: 0;
			transition: all 300ms ease-in-out;
			border-radius: 20px;
		}

		&:before{
			top:0px;
		}
		& span{
			top:8px;
			opacity:1;
		}
		&:after{
			top:16px;
		}
		&.active {
			span {
				opacity:0;
				top:5px;
				width: 30px;
				transform:rotate(45deg);
				background: #000;
			}
			&:before {
				width:30px;
				top:5px;
				left: 0;
				transform:rotate(45deg);
				background: #000;
			}
			&:after {
				width:30px;
				top:5px;
				left: 0;
				transform:rotate(-45deg);
				background: #000;
			}
		}
	}




}