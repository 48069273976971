@media (max-width: 1229px) {
	
	.footer-cols-wrap {
		flex-direction: column;
	}

	.footer {
		&__main {
			flex-wrap: wrap;
		}
	}

	.footer-menu-wrap {
		border-right: 0;
		margin-right: 0;
		margin-bottom: 20px;
	}

	.footer-right {
		align-items: flex-start;
	}
			

}