@media (max-width: 767px) {
	
	.container {
		padding-left: 15px;
		padding-right: 15px;
	}

	.menu {
		opacity: 0;
		visibility: hidden;
		transition: .5s;
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: #fff;
		flex-direction: column;
		align-items: center;
		justify-content: flex-start;
		z-index: 100;
		padding: 50px 15px;
		box-sizing: border-box;
		&>li {
			padding: 0;
			width: 100%;
			text-align: center;
			height: auto;
			margin-bottom: 15px;
			&>a {
				color: #000;
			}
			&.current {
				&:before {
					display: none;
				}
			}
			&:hover {
				.menu__lvl2 {
					display: block;
				}
			}
		}
		&.active {
			visibility: visible;
			opacity: 1;	
		}
		&__lvl2 {
			position: static;
			display: none;
		}
	}
	
	.btn_menu {
		display: block;
	}

	.header {
		&__contacts {
			width: 100%;
			justify-content: center;
			text-align: center;
			margin-top: 10px;
		}
		.container {
			padding-right: 15px;
		}
	}

	.main-slider {
		&__item {
			height: auto;
			padding-top: 200px;
			padding-bottom: 100px;
		}
		.slick-dots {
			right: 15px;
		}
	}

	.section-main {
		&__title {
			font-size: 34px;
			line-height: 40px;
		}
		&__subtitle {
			font-size: 26px;
			line-height: 32px;
		}
	}

	.btn {
		&_theme1 {
			min-width: 100%;
		}
	}


	.pay-logos {
		flex-wrap: wrap;
		padding: 10px;
		&__item {
			margin: 10px;
		}
	}

	.contacts-wrapper {
		flex-wrap: wrap;
	}

	.contacts-col {
		width: 100%;
		margin-right: 0;
		margin-bottom: 30px;
	}

	.contact-form {
		&__field {
			width: 100%;
		}
	}

	.footer {
		&__col1 {
			width: 100%;
		}
		&__section {
			width: 100%;
		}
	}

	.footer-menu {
		margin-right: 0;
		margin-bottom: 8px;
	}

	.footer-menu-wrap {
		flex-direction: column;
	}

	.tariff-col-wrap {
		margin-left: 0;
		margin-bottom: 50px;
	}

	.contacts-form-wrapper {
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}



	
}