/****************************************************
Connect fonts
****************************************************/
@font-face {
    font-family: 'open_sans';
    src: url('../fonts/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'open_sans';
    src: url('../fonts/opensans-extrabold-webfont.eot');
    src: url('../fonts/opensans-extrabold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-extrabold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-extrabold-webfont.woff') format('woff'),
         url('../fonts/opensans-extrabold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-extrabold-webfont.svg#open_sansextrabold') format('svg');
    font-weight: 800;
    font-style: normal;

}

@font-face {
    font-family: 'open_sans';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff2') format('woff2'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#open_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'open_sans';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff2') format('woff2'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'open_sans';
    src: url('../fonts/opensans-semibold-webfont.eot');
    src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff2') format('woff2'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-semibold-webfont.svg#open_sanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}