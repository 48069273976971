.header {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	background: rgba(16,22,54,.2);
	z-index: 100;
	&__logo {
		width: 220px;
	}
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&_inner {
		background: #05121c;
		border-bottom: 2px solid #cfd0d7;
	}
	&.fixed {
		position: fixed;
		background: rgba(16,22,54,.8);
	}
}

.inner-page {
	padding-top: 77px;
}

.contact-item {
	&__link {
		padding-left: 22px;
		position: relative;
		color: #fff;
		text-decoration: none;
		font-size: 13px;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			background-repeat: no-repeat;
		}
		&_phone {
			&:before {
				width: 14px;
				height: 16px;
				background: url(../img/icon-phone.svg);
				background-size: cover;
			}

		}
		&_mail {
			&:before {
				width: 16px;
				height: 11px;
				background: url(../img/icon-mail.svg);
				background-size: cover;
			}
		}
		&_skype {
			&:before {
				width: 16px;
				height: 16px;
				background: url(../img/icon-skype.png);
				background-size: cover;
			}
		}
	}
}

.section-main {
	&__title {
		font-size: 62px;
		line-height: 66px;
		font-weight: 300;
		margin-bottom: 7px;
	}
	&__subtitle {
		font-size: 48px;
		line-height: 60px;
		font-weight: 300;
		margin-bottom: 35px;
	}
}

.menu {
	list-style: none;
	display: flex;
	&>li {
		padding: 25px 15px 0;
		box-sizing: border-box;
		position: relative;
		border-right: 1px solid rgba(255,255,255,.15);
		height: 75px;
		&:last-child {
			border-right: 0;	
		}
		&>a {
			color: #fff;
			font-size: 13px;
			text-decoration: none;
			display: inline-block;
		}
		&.current {
			position: relative;
		}
		&:hover {
			.menu__lvl2 {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__lvl2 {
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		background: rgba(3,7,15,.55);
		list-style: none;
		box-sizing: border-box;
		opacity: 0;
		visibility: hidden;
		transition: .3s;
		&>li {
			text-align: center;
			border-bottom: 1px solid rgba(0,0,0,.5);
			&:last-child {
				margin-bottom: 0;
				border-bottom: 0;
			}
			&>a {
				color: #fff;
				font-size: 13px;
				text-decoration: none;
				display: block;
				padding: 7px 5px;
				&:hover {
					background: rgba(3,7,15,.5);
				}
			}
		}
	}
}

.menu-item-arrow {
	&>a {
		position: relative;
		padding-right: 20px;
		&:after {
			content: "";
			position: absolute;
			right: 0;
			top: 6px;
			width: 12px;
			height: 9px;
			background: url(../img/icon-arrow-down.svg) no-repeat;
		}
	}
}

.btn-wrapper {
	text-align: center;
	&__inner {
		position: relative;
		display: inline-flex;
		&:before {
			content: "";
			position: absolute;
			left: -45px;
			top: 50%;
			width: 30px;
			height: 1px;
			background: #32b0ee;
		}
		&:after {
			content: "";
			position: absolute;
			right: -45px;
			top: 50%;
			width: 30px;
			height: 1px;
			background: #32b0ee;
		}
	}
}

.main-slider {
	position: relative;
	.slick-dots {
		position: absolute;
		right: 45px;
		top: 50%;
		transform: translateY(-50%);
		list-style: none;
		font-size: 0;
		li {
			margin-bottom: 3px;
			button {
				width: 8px;
				height: 8px;
				border: 1px solid rgba(255,255,255,.3);
				border-radius: 100%;
				background: transparent;
			}
			&.slick-active {
				button {
					background: #fff;
				}
			}
		}
	}
	&__item {
		background-repeat: no-repeat;
		background-position: center 0;
		background-size: cover;
		height: 799px;
		box-sizing: border-box;
		padding-top: 356px;
		text-align: center;
		color: #fff;
	}
}


.title {
	text-transform: uppercase;
	text-align: center;
	font-size: 28px;
	line-height: 32px;
	color: #016a76;
	font-weight: 800;
	margin-bottom: 15px;
}

.advantage {
	padding: 0 15px;
	&__icon {
		border: 1px solid #066d79;
		border-radius: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		border-radius: 100%;
		line-height: 0;
		margin-right: 20px;
	}
	&__title {
		color: #062033;
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
	}
	&__head {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
	}
	&__text {
		color: #062033;
		font-size: 13px;
		line-height: 20px;
		padding-left: 60px;
	}
}

.slider-advantages {
	margin: 0 -15px;
	.slick-dots {
		list-style: none;
		font-size: 0;
		text-align: center;
		display: flex;
		justify-content: center;
		margin-top: 18px;
		li {
			margin: 0 7px;
			button {
				width: 8px;
				height: 8px;
				border: 1px solid rgba(0,0,0,.2);
				border-radius: 100%;
				background: transparent;
			}
			&.slick-active {
				button {
					background: #000;
				}
			}
		}
	}
}

.section-advantages {
	background: #f4f7f9;
	padding: 70px 0 40px;
}

.subtitle-figure {
	text-align: center;
	margin-bottom: 45px;
	&__icon {
		position: relative;
		display: inline-block;
		line-height: 0;
		&:before {
			content: "";
			position: absolute;
			left: -100px;
			top: 50%;
			width: 85px;
			height: 1px;
			background: rgba(6,32,51,.1);
		}
		&:after {
			content: "";
			position: absolute;
			right: -100px;
			top: 50%;
			width: 85px;
			height: 1px;
			background: rgba(6,32,51,.1);
		}
	}
}

.block-desc {
	text-align: center;
	color: #062033;
	font-size: 18px;
	line-height: 26px;
	font-weight: 600;
	max-width: 930px;
	margin: 0 auto 55px;
}

.section-tariffs {
	padding: 90px 0 30px;
	background: #f4f7f9;		
	.subtitle-figure {
		margin-bottom: 20px;
	}

	&_spec {
		background: #fff;
	}
}

.list-desc {
	max-width: 930px;
    margin: 0 auto 55px;
    color: #062033;
    font-size: 18px;
    font-weight: 600;
    font-style: italic;
    list-style: none;
    opacity: .8;
    &>li {
    	margin-bottom: 5px;
    	&:last-child {
    		margin-bottom: 0;	
    	}
    }
}

.tariff-block {
	width: 275px;
	box-shadow: 4px 3px 5px 0 rgba(0,0,0,.5);
	background: linear-gradient(to right, #000, #2898c7);
	color: #fff;
	border-radius: 10px;
	padding: 25px 20px;
	margin: 0 25px 30px;
	box-sizing: border-box;
	&__top {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		&__icon {
			margin-right: 10px;
			width: 30px;
			flex-shrink: 0;
			line-height: 0;
		}
		&__title {
			font-size: 24px;
			line-height: 24px;
		}
	}
	&__btn {
		text-align: center;
		position: relative;
		padding-top: 20px;
		.btn {
			min-width: 195px;
			box-shadow: 4px 3px 5px 0 rgba(0,0,0,.2);
		}
	}
	&__price {
		background: #fff;
		border-radius: 0 50px 50px 0;
		height: 103px;
		display: flex;
		justify-content: center;
		flex-direction: column;
		color: #00b5a7;
		font-size: 18px;
		margin: 0 0 28px -20px;
		padding-left: 20px;
		width: 230px;
		box-sizing: border-box;
		&__main {
			font-size: 41px;
			line-height: 41px;
			margin-bottom: 10px;

		}
		&-period {
			text-align: center;
			max-width: 170px;
		}
	}
	.list-check {
		min-height: 96px;
		margin-bottom: 3px;
	}
}

.list-check {
	list-style: none;
	&>li {
		position: relative;
		padding-left: 30px;
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;	
		}
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 23px;
			height: 16px;
			background: url(../img/icon-check.svg) no-repeat;
		}
	}
	&_right-align {
		text-align: right;
		&>li {
			padding-left: 0;
			padding-right: 30px;
			&:before {
				right: 0;
				left: auto;
			}
		}
	}

	&_margin-set {
		&>li {
			margin-bottom: 25px;
			&:last-child {
				margin-bottom: 0;	
			}
		}
	}
}

.tariffs-title {
	text-align: center;
	color: #016a76;
	margin-bottom: 35px;
	font-weight: 600;
	font-size: 28px;
	line-height: 34px;
}

.tariffs-row {
	display: flex;
	margin: 0 -25px;
	justify-content: center;
	flex-wrap: wrap;
}

.tariffs-wrapper {
	margin-bottom: 55px;
	&:last-child {
		margin-bottom: 0;	
	}
}

.section-partners {
	background: #f4f7f9;
}

.section-pay {
	background: url(../img/bg-city.jpg) no-repeat center 0;
	background-size: cover;
	color: #fff;
	padding: 58px 0 32px;
	.title {
		color: #fff;
	}
	.subtitle-figure__icon {
		&:after, &:before {
			background: #8b949c;
		}
	}
}

.pay-cols {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.pay-logos {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	background: rgba(244,247,249,.2);
	padding: 22px;
	margin-top: 50px;
	&__item {
		margin: 0 17px;
		line-height: 0;
	}
}

.pay-types {
	color: rgba(232,233,234,.8);
	margin: 0 15px;
	&__title {
		font-size: 16px;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 27px;
	}
	.list-check {
		font-weight: 600;
		&>li {
			font-size: 16px;
			margin-bottom: 4px;

		}
	}
	&_col1 {
		width: 280px;
	}
	&_col2 {
		width: 320px;
	}
}

.pay-info {
	background: rgba(244,247,249,.2);
	padding: 15px 25px 25px;
	color: rgba(232,233,234,.8);
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	box-sizing: border-box;
	width: 440px;
	border-radius: 12px;
	margin-right: 30px;
}

.contacts-col {
	width: 250px;
	flex-shrink: 0;
	margin-right: 50px;
	&__title {
		margin-bottom: 25px;
		color: #062033;
		font-size: 22px;
		line-height: 22px;
	}
	&__main {
		color: #6a737b;
		font-size: 13px;
		line-height: 24px;
		a {
			color: #6a737b;
			text-decoration: none;
		}
	}
}

.contact-form {
	flex-grow: 1;
	width: 100%;
	&__title {
		color: #062033;
		font-size: 24px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 40px;
	}
	&__main {
		margin: 0 -15px;
		display: flex;
		flex-wrap: wrap;
		max-width: 800px;
	}
	&__field {
		width: 50%;
		padding-left: 15px;
		padding-right: 15px;
		margin-bottom: 35px;
		box-sizing: border-box;
		&__label {
			font-size: 13px;
			color: #5b646e;
			opacity: .5;
		}
		input {
			padding-bottom: 5px;
			width: 100%;
			box-sizing: border-box;
			border-bottom: 1px solid #ccc;
		}
	}
	&__btn {
		margin-top: -15px;
		text-align: right;
		max-width: 770px;
	}
}

.contacts-wrapper {
	display: flex;
}

.icon {
	display: inline-block;
	&-mail {
		width: 23px;
		height: 16px;
		background: url(../img/icon-mail2.svg) no-repeat;
		margin-right: 8px;
	}
	&-arrow-right {
		width: 17px;
		height: 12px;
		background: url(../img/icon-arrow-right.svg) no-repeat;
		background-size: cover;
	}
}

.section-contacts {
	padding-top: 32px;
	padding-bottom: 36px;
	.subtitle-figure {
		margin-bottom: 20px;
	}
}

.footer {
	background: #122536;
	color: #fff;
	line-height: 24px;
	font-size: 13px;
	padding-top: 20px;
	padding-bottom: 20px;
	&__main {
		display: flex;
		justify-content: space-between;
	}
	&__col1 {
		width: 270px;
		margin-right: 30px;
	}
	&__col2 {
		width: 230px;
		padding-top: 25px;
		.footer-title {
			margin-bottom: 20px;
		}
	}
	&__logo {
		width: 220px;
		margin-bottom: 15px;
	}
	&__section {
		padding-top: 25px;
	}
}

.footer-menu-wrap {
	border-right: 1px solid rgba(255,255,255,.05);
	display: flex;
	margin-right: 15px;
	margin-top: 10px;
}

.footer-menu {
	list-style: none;
	margin-right: 15px;
	&>li {
		margin-bottom: 8px;
		&:last-child {
			margin-bottom: 0;	
		}
		&>a {
			font-size: 13px;
			color: #fff;
			text-decoration: none;
			&:hover {
				text-decoration: underline;	
			}
		}
	}
}

.footer-bottom {
	margin-top: 30px;
	a {
		text-decoration: none;
	}
}

.footer-title {
	font-weight: 700;
	font-size: 14px;
	line-height: 24px;
	min-height: 48px;
}

.footer-other {
	width: 240px;
}

.footer-cols-wrap {
	display: flex;
}

.footer-right {
	align-items: center;
}

.link-conf {
	margin-bottom: 50px;
	a {
		text-decoration: none;
		color: #fff;
		&:hover {
			text-decoration: underline;	
		}
	}
}

.tariff-col-wrap {
	width: 270px;
	margin-left: 30px;
	.tariff-block {
		margin: 0;
		&__price {
			margin-bottom: 50px;
		}
	}
}

.contacts-form-wrapper {
	display: flex;
	align-items: center;
}

.form-note {
	font-size: 13px;
	margin-top: 8px;
}

.form-theme-mod2 {
	.contact-form__field__label {
		color: #000;
		opacity: 1;
	}
}

.section-logotypes {
	padding: 70px 0 0px;
}

.logotypes-row {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-around;
	margin-left: -10px;
	margin-right: -10px;
	&__item {
		line-height: 0;
		margin: 0 5px 65px;
	}
}


.modal {
    background: #fff;
    padding: 50px;
    border-radius: 6px;
    text-align: left;
    max-width: 500px;
    box-sizing: border-box;
    margin: 40px auto;
    box-shadow: 0 4px 15px 0 rgba(0,18,84,.07);
    position: relative;
    &__title {
    	font-size: 22px;
    	line-height: 30px;
    	text-align: center;
    	font-weight: 700;
    }
}

