/****************************************************
Base config
****************************************************/
* {
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
}

body {
	font: 14px 'open_sans', Arial, sans-serif;
	width: 100%;
	height: 100%;
	line-height: 20px;
	background: #fff;
}

a {
	color: #00b2fc;
	outline: none;
	text-decoration: underline;
	transition: .5s;
	&:hover {
		text-decoration: none;
	}
}

img {
	border: 0;
	max-width: 100%;
}

input, button, select, textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	-webkit-appearance: none;
	border-radius: 0;
}

button {
	cursor: pointer;
	border: 0;
	outline: none;
}

input {
	vertical-align: middle;
	outline: none;
	border: 0;
	&[type="submit"], &[type="button"] {
		cursor: pointer;
		-webkit-appearance: none;
	}
}

textarea {
	outline: none;
	resize: none;
	overflow: auto;
}

:invalid {
	box-shadow: none;
}

ol, ul {
	list-style-position: inside;
}

::-webkit-input-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

::-moz-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-moz-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:-ms-input-placeholder {
  color: #000;
  opacity: 1;
  transition: opacity 0.3s ease;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.wrapper {
	min-height: 100%;
	height: auto;
	min-width: 320px;
	overflow: hidden;
}

.container {
	max-width: 1170px;
	padding-left: 30px;
	padding-right: 30px;
	margin: 0 auto;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.hidden {
	overflow: hidden;
}

.clearfix {
	&:before {
		content: "";
		display: table;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.win-opened {
	overflow: hidden;
}

/*for sticky footer*/
.content {
	padding-bottom: 345px;
}

.footer {
	margin-top: -345px;
}